<template>
  <b-card>
    <form-component v-if="data" :delivery-info-data="data" @fetchByLocale="fetchByLocale" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/elements/delivery-methods-additional-information/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';

import { show, submit } from '@/http/delivery-methods-additional-info';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      data: null,
    };
  },

  async created() {
    await this.fetchByLocale();
  },

  methods: {
    async fetchByLocale(lang = 'pl') {
      const { data } = await show(lang);

      this.data = data.data;
    },
    async onSubmit(data) {
      console.log(data);
      try {
        await submit(data);

        this.showSuccessNotification('Dane zapisane', 'Dane zostały zapisane.');
      } catch (e) {
        this.showErrorNotification(
          'Problem z zapisaniem danych',
          'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
        );
      }
    },
  },
};
</script>
