<template>
  <validation-observer ref="deliveryAdditionalInfoObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>

        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="fetchByLocale" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Treść</h4>

        <b-form-group label="Tytuł">
          <validation-provider #default="{ errors }" name="Tytuł" rules="required|min:3|max:100">
            <b-form-input id="v-image-title" placeholder="Tytuł" v-model="form.title" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Treść">
          <validation-provider #default="{ errors }" name="Treść" rules="required|min:3|max:400">
            <b-form-textarea v-model="form.value" :disabled="!lang" rows="3" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { getLanguages } from '@/constants/languages';

export default {
  mixins: [NotificationMixin],
  props: {
    deliveryInfoData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      languages: getLanguages(),
      lang: {
        lang: 'pl',
        value: 'Polski',
      },

      form: this.deliveryInfoData,
    };
  },

  watch: {
    deliveryInfoData: {
      handler() {
        this.form = this.deliveryInfoData;
      },
      deep: true,
    },
  },

  methods: {
    onSubmit() {
      this.$emit('onSubmit', {
        ...this.form,
        lang: this.lang.lang,
      });
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.lang.lang);
    },
    validationForm() {
      this.$refs.deliveryAdditionalInfoObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
